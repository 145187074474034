const gameOptions = [
    {name: 'Classic', value: 'classic'},
    {name: 'Restaurant', value: 'restaurant'}
]

export const labels = {
    classic: 'Classic',
    restaurant: 'Restaurant'
}

export default gameOptions;