import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { TextField, Button, Grid, makeStyles, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import axios from 'axios';
import '../index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import slugify from "slugify";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

const NewGame = () => {
  const classes = useStyles();
  const [category,setCategory] = useState('classic');

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.forms.game;
    const userID = JSON.parse(localStorage.getItem('user')).result._id;
    const data = {
      name: form.name.value,
      gameCategory: form.gameCategory.value,
      gameTime: form.gameTime.value,
      numOfQuestions: form.numOfQuestions.value,
      isActive: true,
      createBy: userID
    }
    submitProduct(data); // Call the submitProduct function here
  };

  const submitProduct = async (data) => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/game`;
    const result = await axios.post(`${baseUrl}`, data);
    if (result.status === 201) {
      navigate('/game')
    } else {
      console.log(result);
      alert(result.error)
    }
  }

  return (
    <>
      <React.Fragment>
        <div className='wrapper'>
          <Header />
          <Sidebar />
          <div className='content-wrapper'>
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">Add New Game</h1>
                  </div>{/* /.col */}
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    </ol>
                  </div>{/* /.col */}
                </div>{/* /.row */}
              </div>{/* /.container-fluid */}
            </div>

            <div className='adminform-background px-3 mx-3 py-3'>
              <form onSubmit={handleSubmit} name="game">
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="level-label">Type</InputLabel>
                      <Select
                        labelId="team-select-label"
                        id="game-category"
                        name='gameCategory'
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        style={{ width: "100%" }}
                      >
                        <MenuItem key={'classic'} value={'classic'}>{'Classic'}</MenuItem>
                        <MenuItem key={'restaurant'} value={'restaurant'}>{'Restaurant'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Name"
                      name="name"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>

                    <TextField
                      label="Game Time"
                      name="gameTime"
                      placeholder='Each level time in seconds'
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>

                    <TextField
                      label="Number of questions"
                      name="numOfQuestions"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit">
                      Create Game
                    </Button>
                  </Grid>
                </Grid>

              </form>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    </>
  )
}

export default NewGame;