import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { TextField, Button, Grid, makeStyles, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import axios from 'axios';
import '../index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import slugify from "slugify";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

const Config = () => {
  const classes = useStyles();
  const [category,setCategory] = useState('classic');
  const [formData, setFormData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const result = await axios.get(`${process.env.REACT_APP_BACKEND}/api/config`);
      if (result.status === 200) {
        //console.log(result.data);
        setFormData(result.data);
      }
    }
    getData();
  },[])

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.forms.appConfig;
    const data = {
      boostPrice: form.name.boostPrice,
      invitationBonus: form.invitationBonus.value,
      appStoreUrl: form.appStoreUrl.value,
      playStoreUrl: form.playStoreUrl.value,
      tokenRate: form.tokenRate.value,
      _id: formData._id
    }
    submitProduct(data); // Call the submitProduct function here
  };

  const submitProduct = async (data) => {

    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/config/${data._id}`;
    const result = await axios.put(`${baseUrl}`, data);
    if (result.status === 200) {
      navigate('/config')
    } else {
      console.log(result);
      alert(result.error)
    }
  }

  return (
    <>
      <React.Fragment>
        <div className='wrapper'>
          <Header />
          <Sidebar />
          <div className='content-wrapper'>
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">App Config</h1>
                  </div>{/* /.col */}
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    </ol>
                  </div>{/* /.col */}
                </div>{/* /.row */}
              </div>{/* /.container-fluid */}
            </div>

            <div className='adminform-background px-3 mx-3 py-3'>
              <form onSubmit={handleSubmit} name="appConfig">
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <TextField
                      label="Boost Price"
                      name="boostPrice"
                      fullWidth
                      value={formData?.boostPrice}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Invitation Bonus"
                      name="invitationBonus"
                      value={formData?.invitationBonus}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Token Rate"
                      name="tokenRate"
                      value={formData?.tokenRate}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="App Store Url"
                      name="appStoreUrl"
                      fullWidth
                      value={formData?.appStoreUrl}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Play Store Url"
                      name="playStoreUrl"
                      fullWidth
                      value={formData?.playStoreUrl}
                      required
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TextField
                      label="Stripe Public Key"
                      name="publicKey"
                      placeholder='Each level time in seconds'
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Stripe Secret Key"
                      name="secretKey"
                      fullWidth
                      required
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>

              </form>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    </>
  )
}

export default Config;