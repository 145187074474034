import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer class="main-footer">
    <strong> &copy; </strong>
    All rights reserved by JOOT.
    <div class="float-right d-none d-sm-inline-block">
      {/* <b>Version</b> 3.2.0 */}
    </div>
  </footer>

      </div>
    )
  }
}
