import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { TextField, Button, Grid, makeStyles, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import axios from 'axios';
import '../index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import slugify from "slugify";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

const NewQuizCategory = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');

  const navigate = useNavigate();


  const fetchData = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/quizLevels`);
    const result = await response.json();
    setLevels(result);
  };
  useEffect(() => {
    fetchData();
  }, []);



  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const userID = JSON.parse(localStorage.getItem('user')).result._id;
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/quizCategories-add`;
    const data = {
      name: name,
      description: description,
      //selectedLevel: selectedLevel,
      userID: userID
    };
    const formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    // formData.append("file", file);
    const result = await axios({
      method: 'post',
      url: baseUrl,
      data: formData,
      headers: {'Content-Type' : 'application/json'},
    });
    //const result = await axios.post(baseUrl, data);
    if (result) {
      console.log(result);
      navigate('/quizcategory-list')
    }


  }

  // const saveFile = (e) => {
  //     setFile(e.target.files[0]);
  //     setFileName(e.target.files[0].name);
  // };

  return (
    <>

      <div className='content-wrapper'>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Add New Quiz Category</h1>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>

        <div className='adminform-background px-3 mx-3 py-3'>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>

              <Grid item xs={6}>

                <TextField
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>

                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>

              {/* <Grid item xs={6}>


                <Select
                  labelId="team-select-label"
                  id="team-select"
                  value={selectedLevel}
                  onChange={handleLevelChange}
                  style={{ width: "100%" }}
                >
                  {levels.map((level) => (
                    <MenuItem key={level._id} value={level._id}>
                      {level.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid> */}
              <Grid item xs={12}>

                <Button variant="contained" color="primary" type="submit">
                  Create Quiz Category
                </Button>
              </Grid>
            </Grid>

          </form>
        </div>




      </div>


    </>
  )
}

export default NewQuizCategory