import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.png'
// import favicon from "../../assets/favicon.webp"
import menuList from './menuList';

import '../index.css'

export default class Sidebar extends Component {
  constructor(props){
    super(props);
    this.state = {
      user : {},
      role : "admin"
    }
  }

  componentDidMount(){
    const auth = localStorage.getItem('user')
    if(auth){
      const user = JSON.parse(auth);
      this.setState({
        user: user,
        role: user.result.role
      })
    }
  }

  render() {
    return (
      <div>
        <aside className="main-sidebar admin-sidebar">
          {/* Brand Logo */}
          <a className="brand-link">
            <img src={logo} />
          </a>
          {/* Sidebar */}
          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">

              <div className="info">
                <p className='text-white'>Super Admin</p>
              </div>
            </div>
            {/* SidebarSearch Form */}
            <div className="form-inline">
              <div className="input-group" data-widget="sidebar-search">
                <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                  <button className="btn btn-sidebar">
                    <i className="fas fa-search fa-fw" />
                  </button>
                </div>
              </div>
            </div>
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                <li className="nav-item">
                  <Link to='/dashboard'>
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-tachometer-alt" />
                      <p>
                        Dashboard
                      </p>
                    </a>
                  </Link>
                </li>
                {
                  menuList[this.state.role ?? 'admin'].map(item => (
                    <li className="nav-item">
                      {!item.disabled &&
                        <Link to={item.link}>
                          <a className="nav-link">
                            <i className="nav-icon fas fa-th" />
                            <p>
                              {item.item}
                            </p>
                          </a>
                        </Link>
                      }
                    </li>
                  ))
                }
                {/* <li className="nav-item">
              <Link to='/pages-list'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Pages
                  </p>
                </a>
              </Link>
        </li>
        <li className="nav-item">
              <Link to='/resources-newsletter-list'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Resources
                  </p>
                </a>
                </Link>
        </li>
        
        <li className="nav-item">
              <Link to='/partner-list'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Partnerships
                  </p>
                </a>
                </Link>
        </li>
        <li className="nav-item">
              <Link to='/memberships-list'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Memberships
                  </p>
                </a>
                </Link>
        </li>
        
        
        <li className="nav-item">
              <Link to='/team-list'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Team Members
                  </p>
                </a>
                </Link>
        </li>
        <li className="nav-item">
              <Link to='/events-list'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Events
                  </p>
                </a>
                </Link>

              </li>
              <li className="nav-item">
              <Link to='/webinar-list'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Webinars
                  </p>
                </a>
                </Link>

              </li>

              <li className="nav-item">
              <Link to='/packages-list'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Packages
                  </p>
                </a>
                </Link>

              </li>
              <li className="nav-item">
              <Link to='/outsource-data'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    OutSources
                  </p>
                </a>
                </Link>

              </li>
              <li className="nav-item">
              <Link to='/additionalresources-list'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Additional Resources
                  </p>
                </a>
                </Link>

              </li>
              <li className="nav-item">
              <Link to='/accordians-list'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Accordians
                  </p>
                </a>
                </Link>

              </li>
        <li className="nav-item">
          <a href="#" className="nav-link">
            <i className="nav-icon fas fa-copy" />
            <p>
              Form Entries
              <i className="fas fa-angle-left right" />
            </p>
          </a>
          <ul className="nav nav-treeview">
                <li className="nav-item">
              <Link to='/contact-entries'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Contact Entries
                  </p>
                </a>
                </Link>
              </li>
              <li className="nav-item">
              <Link to='/newsletter-entries'>
                <a  className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Newsltter Entries
                  </p>
                </a>
                </Link>

              </li>
              


          </ul>
        </li> */}



              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
          {/* /.sidebar */}
        </aside>


      </div>
    )
  }
}
