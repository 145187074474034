import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const formRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      email,
      password,
    };

    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_BACKEND}/api/login-customer`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj),
      });

      const content = await rawResponse.json();

      if (content.status === 'success') {
        if (content.data.status === 'approved') {
          navigate('/customer-signup');
        } else {
          alert('Your account is not approved by the admin');
        }
      } else {
        alert('Invalid email or password');
      }
    } catch (error) {
      console.log(error);
      alert('An error occurred while logging in');
    }
  };

  return (
    <>
      <div className='hold-transition login-page'>
        <img src={logo} alt='Logo' />
        <div className='login-box'>
          <div className='card card-outline card-primary'>
            <div className='card-header text-center'></div>
            <div className='card-body'>
              <p className='login-box-msg'>Sign in to start your session</p>
              <form ref={formRef} onSubmit={handleSubmit}>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className='input-group-append'>
                    <div className='input-group-text'>
                      <span className='fas fa-envelope' />
                    </div>
                  </div>
                </div>
                <div className='input-group mb-3'>
                  <input
                    type='password'
                    className='form-control'
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className='input-group-append'>
                    <div className='input-group-text'>
                      <span className='fas fa-lock' />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <button type='submit' className='btn btn-primary btn-block'>
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
