import React, { useState,useEffect,useRef  } from 'react';
import { Link , useNavigate} from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { TextField, Button, Grid, makeStyles , InputLabel , Select, MenuItem} from '@material-ui/core';
import axios from 'axios';
import '../index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import slugify from "slugify";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

const NewBlog = () => {
    const classes = useStyles();
    const [posttitle, setName] = useState('');
    const [description, setDesc] = useState('');
    const [excerpt, setExcerpt] = useState('');
    const [metatitle, setMetatitle] = useState('');
    const [metadescription, setMetadescription] = useState('');
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [error, SetError]=useState("");
    const [tags, setTags] = useState([]);
    const [slug, setSlug] = useState("");
    const [date, setDate] = useState(new Date());

    const navigate = useNavigate();
    
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-team`);
      const result = await response.json();
      setTeams(result);
    };
    useEffect(() => {
      fetchData();
    }, []);


    const handleChange = (event) => {
      setSelectedTeam(event.target.value);
    };
    const handleNameChange = (event) => {
        setName(event.target.value);

      };
      const handleSlugChange = (event) => {
        setSlug(event.target.value);
      }

      const handleExcerptChange = (event) => {
        setExcerpt(event.target.value);
      };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      submitProduct(); // Call the submitProduct function here
    };

    const submitProduct= async () =>{      
        const userID = JSON.parse(localStorage.getItem('user')).result._id;
        const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-posts`;
        const data = { 
          posttitle:posttitle,
          description: description,
          excerpt:excerpt,
          tags:tags,      
          slug: slug,
          metatitle:metatitle,
          metadescription:metadescription, 
          date:date,
          selectedTeam:selectedTeam,
          userID:userID
        };
        const formData = new FormData();
        for (let name in data) {
            formData.append(name, data[name]);
        }
        formData.append("file", file);
        const result = await axios({
                method: 'post',
                url:baseUrl,
                data: formData,
                headers: {'Content-Type' : 'application/json'},
            });
        //const result = await axios.post(baseUrl, data);
        if(result){
            console.log(result);
            navigate('/post-list')
        }
    
    
    }

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    
  
    const handleTagChange = (tags) => {
      setTags(tags);
    };

  return (
        <>
        
        <div className='content-wrapper'>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Add New Post</h1>
            </div>{/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
              </ol>
            </div>{/* /.col */}
          </div>{/* /.row */}
        </div>{/* /.container-fluid */}
      </div>
      
      <div className='adminform-background px-3 mx-3 py-3'>
      <form className={classes.root} onSubmit={handleSubmit}>
      <Grid container spacing={4}>
      <Grid item xs={12}>
          <TextField
            required
            label="Post Title"
            value={posttitle}
            onChange={handleNameChange}
          />
          <TextField
            required
            label="Post slug"
            value={slug} onChange={handleSlugChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Post Excerpt"
            value={excerpt}
            onChange={handleExcerptChange}
          />
        </Grid>
        <Grid item xs={12}>
        <InputLabel htmlFor="name-input">Post Description</InputLabel>

        <Editor
              textareaName="product Description"
              value={description} 
              //onEditorChange={(e)=>SetDescription(e.target.value)} 
              onEditorChange={(newValue, editor) => setDesc(newValue)}
              init={{
                  height: 500,
                  menubar: true,
                  plugins: 'autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',

                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
          />
        </Grid>
        
        <Grid item xs={6}>
        <InputLabel htmlFor="name-input">Add Seo Tags</InputLabel>

                <TagsInput value={tags} onChange={handleTagChange} />
        </Grid>
        <Grid item xs={6}>
          <input
            accept="image/*"
            id="thumbnail-upload"
            type="file"
            onChange={saveFile}
            style={{ display: 'none' }}
          />
           {fileName && (
            <div className='img-box-dashboard-featured'>
            <img
              src={URL.createObjectURL(file)}
              alt="Selected file preview"
              
            />
            </div>
          )}
          <label htmlFor="thumbnail-upload">
            <Button variant="contained" color="primary" component="span">
              Upload Resource 
            </Button>
          </label>
         
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Meta Title"
            value={metatitle} onChange={(e)=>setMetatitle(e.target.value)} 
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Meta Description"
            value={metadescription} onChange={(e)=>setMetadescription(e.target.value)} 
          />
        </Grid>
        <Grid item xs={6}>

        <InputLabel id="team-select-label">Select Author</InputLabel>
      <Select
        labelId="team-select-label"
        id="team-select"
        value={selectedTeam}
        onChange={handleChange}
        style={{ width: "100%" }}
      >
        {teams.map((team) => (
          <MenuItem key={team._id} value={team._id}>
            <img className="table-backend-img-author" src={`${process.env.REACT_APP_BACKEND}/uploads/team/${team.document}`}/> -  {team.membername}
          </MenuItem>
        ))}
      </Select>
      </Grid>

      <Grid item xs={6}>

      <TextField
        type="date"
        label="Date"
        required
        value={date.toISOString().substr(0, 10)}
        onChange={(event) => setDate(new Date(event.target.value))}
        InputLabelProps={{ shrink: true }}
      />
      </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
    </div>




    </div>
        
        
        </>
    )
}

export default NewBlog