import React from 'react'
import Login from './Login'
const index = () => {
  return (
    <>
    <React.Fragment>
    <div className='wrapper'>

        <Login/>
        </div>
    </React.Fragment>
    
    </>


    )
}

export default index