export default function formatDateToCustomString(dateString) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const date = new Date(dateString);
  
    // Get the day of the week, e.g., "Fri"
    const dayOfWeek = date.toLocaleString('en-US', { weekday: 'short' });
  
    // Get the day of the month, e.g., "21"
    const dayOfMonth = date.getDate();
  
    // Get the abbreviated month name, e.g., "Feb"
    const month = months[date.getMonth()];
  
    // Get the year, e.g., "2014"
    const year = date.getFullYear();
  
    // Get the time in AM/PM format, e.g., "09:40 AM"
    const time = date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  
    // Concatenate the parts to form the desired format
    const formattedDate = `${dayOfWeek} ${dayOfMonth} ${month} ${year}, ${time}`;
  
    return formattedDate;
  }