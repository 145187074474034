import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/logo.png'
import '../index.css'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
const Signup = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const histoty = useNavigate()
  const formRef = useRef();


  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = { name, email, password, role, isActive: false };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/customer`, userData);
      if(!response?.error){
        //localStorage.setItem('user',JSON.stringify(response))
        histoty('/login')
      }else{
        alert('User not inserted');
      }
      // // Reset form values
      // setName('');
      // setEmail('');
      // setPassword('');
      // setRole('');
    } catch (error) {
      console.error(error);
    }
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };


  return (
    <>
      <div className='hold-transition login-page'>
        <img src={logo} />
        <div className="login-box">
          {/* /.login-logo */}
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              {/* <a href="#" className="h1"><b>JOOT</b>Admin</a> */}
            </div>
            <div className="card-body">
              <p className="login-box-msg">Sign Up</p>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Restuarant"
                  value={fullName}
                  onChange={(event) => setFullName(event.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  fullWidth
                  margin="normal"
                />
                <FormControl fullWidth>
                  <InputLabel id="role-label">Role</InputLabel>
                  <Select
                    labelId="role-label"
                    value={role}
                    onChange={handleRoleChange}
                  >
                    <MenuItem value="basic">Normal Signup</MenuItem>
                    <MenuItem value="restuarant">Restaurant Referral</MenuItem>
                  </Select>
                </FormControl>
                <div className="row pt-5">

                  <div className="col-12">
                    <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
                  </div>
                </div>
                <div className="row pt-2">

                  <div className="col-12">
                    <Link to={'/login'}>
                      <button className="btn btn-primary btn-block">Sign In</button>
                    </Link>
                  </div>
                </div>
              </form>

            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
      </div>
    </>
  )
}

export default Signup