import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { TextField, Button, Grid, makeStyles, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import axios from 'axios';
import '../index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import slugify from "slugify";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

const NewDeal = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.forms.deal;
    const userID = JSON.parse(localStorage.getItem('user')).result._id;
    const data = {
      name: form.name.value,
      description: form.description.value,
      code: form.code.value,
      discount: form.discount.value,
      dealTotalAmount: form.dealTotalAmount.value,
      startDate: form.startDate.value,
      endDate: form.endDate.value,
      restaurantId: userID
    }
    submitProduct(data); // Call the submitProduct function here
  };

  const submitProduct = async (data) => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/deals`;
    const result = await axios.post(`${baseUrl}`,data);
    if(result.status === 201){
      navigate('/deals-list')
    }else{
      console.log(result);
      alert(result.error)
    }
  }

  return (
    <>
      <div className='content-wrapper'>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Add New Deal</h1>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>

        <div className='adminform-background px-3 mx-3 py-3'>
          <form onSubmit={handleSubmit} name="deal">
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  name="name"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>

                <TextField
                  label="Description"
                  name="description"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>

                <TextField
                  label="Start Date"
                  name="startDate"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>

                <TextField
                  label="End Date"
                  name="endDate"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>

                <TextField
                  label="Code"
                  name='code'
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Discount"
                  name='discount'
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Total Discount"
                  name='dealTotalAmount'
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Create Deal
                </Button>
              </Grid>
            </Grid>

          </form>
        </div>
      </div>
    </>
  )
}

export default NewDeal;