import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { Link, useNavigate } from "react-router-dom";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import axios from 'axios';

const QuestionForm = () => {
  const [levels, setLevels] = useState([]);
  const [categories, setCategories] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [category, setCategory] = useState('');
  const [game, setGame] = useState();
  const [token, setToken] = useState(1);
  const [levelDifficulty, setDifficulty] = useState('');
  const [levelType, setType] = useState('multiple');
  const [questionText, setQuestionText] = useState('');
  const [options, setOptions] = useState(['']);
  const [correctOption, setCorrectOption] = useState('');

  const difficulty = [
    {key: 'easy', value: 'Easy'},
    {key: 'medium', value: 'Medium'},
    {key: 'hard', value: 'Hard'},
  ]
  const type = [
    {key: 'multiple', value: 'Multiple'},
    {key: 'true or false', value: 'True Or False'}
  ]
  const navigate = useNavigate();

  const fetchLevels = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/quizLevels`);
    const result = await response.json();
    setLevels(result);
  };

  const fetchGames = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/game`);
    const result = await response.json();
    setGames(result);
  };

  const fetchCategories = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/category`);
    const result = await response.json();
    setCategories(result);
    fetchLevels();
    fetchGames();
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  const handleOptionChange = (event, index) => {
    const updatedOptions = [...options];
    updatedOptions[index] = event.target.value;
    setOptions(updatedOptions);
  };

  const handleCorrectOptionChange = (event) => {
    setCorrectOption(event.target.value);
  };

  const addOption = () => {
    const updatedOptions = [...options, ''];
    setOptions(updatedOptions);
  };

  const removeOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const userID = JSON.parse(localStorage.getItem('user')).result._id;
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/questions`;

    const data = {
      selectedLevel: selectedLevel,
      questionText: questionText,
      options: options,
      correctOption: correctOption,
      userID: userID,
      difficulty :levelDifficulty,
      type :levelType,
      gameId : game,
      categoryId: category
    };
    // const formData = new FormData();
    // for (let name in data) {
    //   formData.append(name, data[name]);
    // }
    // formData.append("file", file);
    const result = await axios.post(baseUrl,data);
    //const result = await axios.post(baseUrl, data);
    if (result) {
      
      navigate('/question-list')
    }
  }

  return (
    <div className='content-wrapper'>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Add New Question</h1>
            </div>{/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
              </ol>
            </div>{/* /.col */}
          </div>{/* /.row */}
        </div>{/* /.container-fluid */}
      </div>
      <div className='adminform-background px-3 mx-3 py-3'>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="level-label">Category</InputLabel>
                <Select
                  labelId="team-select-label"
                  id="team-select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  style={{ width: "100%" }}
                >
                  {categories.map((level) => (
                    <MenuItem key={level._id} value={level._id}>
                      {level.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="level-label">Game</InputLabel>
                <Select
                  labelId="team-select-label"
                  id="game-select"
                  name='gameId'
                  value={game}
                  onChange={(e) => setGame(e.target.value)}
                  style={{ width: "100%" }}
                >
                  {games.map((x) => (
                    <MenuItem key={x._id} value={x._id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="level-label">Level</InputLabel>
                <Select
                  labelId="team-select-label"
                  id="team-select"
                  value={selectedLevel}
                  onChange={handleLevelChange}
                  style={{ width: "100%" }}
                >
                  {levels.map((level) => (
                    <MenuItem key={level._id} value={level._id}>
                      {level.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="level-label">Difficulty</InputLabel>
                <Select
                  labelId="team-select-label"
                  id="difficulty-select"
                  value={levelDifficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                  style={{ width: "100%" }}
                >
                  {difficulty.map((level) => (
                    <MenuItem key={level.key} value={level.key}>
                      {level.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Token"
                name="token"
                value={token}
                placeholder='Win Tokens'
                onChange={(event) => setToken(event.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={4} style={{display:'none'}}>
              <FormControl fullWidth>
                <InputLabel id="level-label">Type</InputLabel>
                <Select
                  labelId="team-select-label"
                  id="type-select"
                  value={levelType}
                  onChange={(e) => setType(e.target.value)}
                  style={{ width: "100%" }}
                >
                  {type.map((level) => (
                    <MenuItem key={level.key} value={level.key}>
                      {level.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>

              <TextField
                label="Question Text"
                value={questionText}
                onChange={(event) => setQuestionText(event.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>

              {options.map((option, index) => (
                <div key={index} className="option-container">
                  <TextField
                    label={`Option ${index + 1}`}
                    value={option}
                    onChange={(event) => handleOptionChange(event, index)}
                    fullWidth
                    margin="normal"
                  />
                  {index === options.length - 1 && (
                    <AddCircleOutlineIcon onClick={addOption} className="add-option" />
                  )}
                  {index !== options.length - 1 && (
                    <RemoveCircleOutlineIcon onClick={() => removeOption(index)} className="remove-option" />
                  )}
                </div>
              ))}
            </Grid>
            <Grid item xs={12}>

              <FormControl fullWidth>
                <InputLabel id="correct-option-label">Correct Option</InputLabel>
                <Select
                  labelId="correct-option-label"
                  value={correctOption}
                  onChange={handleCorrectOptionChange}
                >
                  {options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default QuestionForm;
