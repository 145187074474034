import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import '../../index.css'
import axios from 'axios';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import formatDateToCustomString from '../../utils';

const CashWithdrawl = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    const user = JSON.parse(localStorage.getItem('user')).result;
    
    const result = await axios.get(`${process.env.REACT_APP_BACKEND}/api/payment/getRequest/`);
    if (result.status === 200) {
      setData(result.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = async (id, status) => {
    if (id) {
      let result = await axios.put(`${process.env.REACT_APP_BACKEND}/api/payment/updateRequestStatus/${id}`, { isApproved: !status });
      if (result.status === 200) {
        const index = data.findIndex(x => x._id === id);
        const row = data[index];
        row.isApproved = !row.isApproved;
        let newData = data;
        newData.splice(index, 1, row);
        setData(newData);
      }
    }
  }

  return (
    <React.Fragment>
      <div className='wrapper'>
        <Header />
        <Sidebar />
        <div className='content-wrapper'>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0"> Win Game User List</h1>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}
          </div>
          <div className='mx-3'>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S.no #</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Cash App Id</TableCell>
                    <TableCell>Tokens</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Conversion Rate</TableCell>
                    <TableCell>Is Payed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(data) && data.length > 0 ? (
                    data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <TableRow key={row._id}>
                        <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                        <TableCell>{formatDateToCustomString(new Date(row.created))}</TableCell>
                        <TableCell>{row.userId.fname}</TableCell>
                        <TableCell>{row.cashAppId}</TableCell>
                        <TableCell>${row.tokens}</TableCell>
                        <TableCell>${row.amount}</TableCell>
                        <TableCell>${row.conversionRate}</TableCell>
                        <TableCell>
                          {/* <Link to={"/update-blogpost/"+row.slug}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link> */}
                          {/* <DeleteTwoToneIcon onClick={() =>deleteDeal(row._id)}></DeleteTwoToneIcon> */}
                          <FormGroup>
                            <FormControlLabel control={<Switch defaultChecked={row.isApproved} disabled={row.isApproved} onChange={() => handleStatus(row._id, row.isApproved)} />} label="" />
                          </FormGroup>
                        </TableCell>

                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>No records found.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default CashWithdrawl;
