import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/logo.png'
import '../index.css'

const Login = () => {
  const [lemail, SetLEmail] = useState("");
  const [Lpassword, SetLPassword] = useState("");
  const [fname, SetFullname] = useState("");
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [repassword, Setrepassword] = useState("");
  const navigate = useNavigate();

  const histoty = useNavigate()
  const formRef = useRef();


  useEffect(() => {
    const auth = localStorage.getItem('user')
    if (auth) {
      histoty('/dashboard')
    }
  }, [])

  const handleSubmit = async (e, formRef) => {
    e.preventDefault();
    // const form = document.forms.login
    const obj = {
      email: formRef.current.email.value,
      password: formRef.current.password.value,
    }

    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_BACKEND}/api/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      });

      if (!rawResponse.ok) {
        throw new Error(rawResponse.statusText);
      }
      const content = await rawResponse.json();
      localStorage.setItem("user", JSON.stringify(content));
      if (content.status == 'success') {
        histoty('/dashboard')
      }
    } catch (error) {
      console.log(error)
      alert('invalid user nmae or pasword')
    }


  }
  return (
    <>
      <div className='hold-transition login-page'>
        <img src={logo} />
        <div className="login-box">
          {/* /.login-logo */}
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              {/* <a href="#" className="h1"><b>JOOT</b>Admin</a> */}
            </div>
            <div className="card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <form ref={formRef} onSubmit={(e) => handleSubmit(e, formRef)}>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" name='email' placeholder="Email" />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input type="password" className="form-control" name='password' placeholder="Password" />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="row">

                  {/* /.col */}
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                  </div>
                  {/* /.col */}
                </div>
              </form>
              <div className="col-12">
                <Link to={'/signup'}>
                  Click here to register
                </Link>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
      </div>
    </>
  )
}

export default Login