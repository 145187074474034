import React from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Sidebar from '../../Components/Sidebar';
import RestaurantList from '../List/RestaurantList'
const index = () => {
  return (
        <React.Fragment>
            <div className='wrapper'>
                <Header/>
                <Sidebar/>
                    <RestaurantList />
                <Footer/>
            </div>
        </React.Fragment>
    )
}

export default index