import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Switch,
} from '@material-ui/core';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import '../../index.css';
import axios from 'axios';

const Data = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/customer`);
    const result = await response.json();
    if (result.result === 'No posts Found') {
      setData([]);
    } else {
      setData(result);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleStatus = async (id, currentStatus) => {
    const updatedStatus = currentStatus === 'approved' ? 'pending' : 'approved';
    try {
      await axios.patch(`${process.env.REACT_APP_BACKEND}/api/customer/${id}`, { status: updatedStatus });
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const DeletePartnership = async (id) => {
    let result = await axios.delete(`${process.env.REACT_APP_BACKEND}/api/customer/${id}`);
    fetchData();
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">All Users</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                {/* <Link to='/quizlevel-new'>
              <Button variant="primary" size='lg'>Add New</Button>
              </Link> */}
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="mx-3">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.no #</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.length > 0 ? (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row._id}>
                      <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.role}</TableCell>
                      <TableCell>
                        <Switch
                          checked={row.status === 'approved'}
                          onChange={() => handleToggleStatus(row._id, row.status)}
                          color="primary"
                          name="status"
                          inputProps={{ 'aria-label': 'toggle status' }}
                        />
                      </TableCell>
                      <TableCell>
                        {/* <Link to={"/update-blogpost/"+row.slug}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link> */}
                        <DeleteTwoToneIcon onClick={() => DeletePartnership(row._id)} />
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No records found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
  );
};

export default Data;
