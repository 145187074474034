const menuList = {
    "admin":[
            { item: 'Quiz Category', link: '/quizcategory-list', disabled: false},
            { item: 'Quiz Level', link: '/quizlevel-list', disabled: false},
            { item: 'Questions', link: '/question-list', disabled: false},
            { item: 'Deals', link: '/deals-list', disabled: false},
            { item: 'Restaurant', link: '/restaurants', disabled: false},
            { item: 'Users', link: '/users-list', disabled: false},
            { item: 'Games', link: '/game', disabled: true},
            { item: 'Tokens', link: '/item', disabled: false},
            { item: 'Win Game List', link: '/wingame', disabled: false},
            { item: 'Configuration', link: '/config', disabled: false},
            { item: 'Cashwithdrawl Request', link: '/cashwithdrawl', disabled: false},
        ],
    "restuarant":[
        { item: 'Questions', link: '/question-list', disabled: false},
        { item: 'Deals', link: '/deals-list', disabled: false},
        { item: 'Win Game List', link: '/wingame', disabled: false},
    ]
};

export default menuList;