import React, { useState,useEffect,useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import PrivateRoute from './PrivateRoute';
import Dashboard from './Dashboard/Home/index';
import Login from './Dashboard/Login/index';
import DataBlog from './Dashboard/Blogs/data/index';
import NewBlog from './Dashboard/Blogs/index';
import UpdateBlogpost from './Dashboard/Blogs/update/index'
import NewQuiz  from './Dashboard/QuizCategory/index'
import NewDeal  from './Dashboard/Deal/index'
import QuizCategoryList from './Dashboard/QuizCategory/data/index'
import Restaurant from './Dashboard/Restaurant/List/index'
import NewQuizLevel from './Dashboard/QuizLevel/index';
import QuizLevelData from './Dashboard/QuizLevel/Data/index'
import Deal from './Dashboard/Deal/List/index'
import Questionlist from './Dashboard/Question/data/index'
import NewQuestion from './Dashboard/Question/index'
import GameList from './Dashboard/Game/index'
import Game from './Dashboard/Game/Game'
import ItemList from './Dashboard/Item/index'
import Item from './Dashboard/Item/Item'
import Signup from './Dashboard/Signup/index';
import CutomerList from './Dashboard/Signup/data/index';
import Custoemrlogin  from './Dashboard/Customer-login/index'
import WinGame from './Dashboard/WinGame/index';
import Config from './Dashboard/Config';
import CashWithdrawl from './Dashboard/CashWithdrawl';

function App() {

  const auth=localStorage.getItem('user')

  const [cart,setCart] = useState([])
  useEffect(() => {
    if(localStorage.getItem('cart')!=null && localStorage.getItem('cart') != 'null'){
      setCart(JSON.parse(localStorage.getItem('cart')).cart)
    }
  },[])

  return (

    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Login/>}/>
      
            <Route exact path="/login" element={<Login/>}/>
            <Route exact path="/signup" element={<Signup/>}/>
            <Route exact path="/customer-login" element={<Custoemrlogin/>}/>

            
            <Route element={<PrivateRoute/>}>

            <Route exact path="/dashboard" element={<Dashboard/>}/>
            {/* <Route exact path="/post-new" element={<NewBlog/>}/>
            <Route exact path="/post-list" element={<DataBlog/>}/> */}
            {/* <Route exact path="/update-blogpost/:slug" element={<UpdateBlogpost/>}/> */}
            <Route exact path="/quizcategory-new" element={<NewQuiz/>}/>
            <Route exact path="/quizcategory-list" element={<QuizCategoryList/>}/>
            <Route exact path="/quizlevel-new" element={<NewQuizLevel/>}/>
            <Route exact path="/quizlevel-list" element={<QuizLevelData/>}/>
            <Route exact path="/deals-list" element={<Deal/>}/>
            <Route exact path="/deal-new" element={<NewDeal/>}/>
            <Route exact path="/game-new" element={<Game/>}/>
            <Route exact path="/game" element={<GameList/>}/>
            <Route exact path="/item-new" element={<Item/>}/>
            <Route exact path="/item" element={<ItemList/>}/>
            <Route exact path="/config" element={<Config/>}/>
            <Route exact path="/restaurants" element={<Restaurant/>}/>
            <Route exact path="/question-list" element={<Questionlist/>}/>
            <Route exact path="/new-question" element={<NewQuestion/>}/>
            <Route exact path="/users-list" element={<CutomerList/>}/>
            <Route exact path="/wingame" element={<WinGame/>}/>
            <Route exact path="/cashwithdrawl" element={<CashWithdrawl/>}/>

            </Route>

        </Routes>
    </BrowserRouter>
    

  );
}

export default App;