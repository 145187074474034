import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import '../../index.css'
import axios from 'axios';

const RestaurantList = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    const result = await axios.get(`${process.env.REACT_APP_BACKEND}/api/user/users/restuarant`);
    if(result.status === 200)
    {
      setData(result.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const deleteDeal = async (id)=>{
    let result = await axios.delete(`${process.env.REACT_APP_BACKEND}/api/deals/${id}`)
    fetchData();     
  };

  const handleStatus = async (id,status) => {
    if(id){
      let result = await axios.put(`${process.env.REACT_APP_BACKEND}/api/user/updateStatus/${id}`,{ isActive: !status });
      if(result.status === 200){
        const index = data.findIndex(x => x.restaurantId === id);
        const row = data[index];
        row.isActive = !status;
        let newData = data;
        newData.splice(index,1,row);
        setData(newData);
      }
    }
  }
  
  return (
    <div className='content-wrapper'>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Restaurant List</h1>
            </div>{/* /.col */}
          </div>{/* /.row */}
        </div>{/* /.container-fluid */}
      </div>
      <div className='mx-3'>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.no #</TableCell>                
              <TableCell>Name</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
          {Array.isArray(data) && data.length > 0 ? (
            data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={row._id}>
                 <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                <TableCell>{row.fname}</TableCell>
                <TableCell>{row.fullName}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                {/* <Link to={"/update-blogpost/"+row.slug}><ModeEditOutlineTwoToneIcon></ModeEditOutlineTwoToneIcon></Link> */}
                  {/* <DeleteTwoToneIcon onClick={() =>deleteDeal(row._id)}></DeleteTwoToneIcon> */}
                  <FormGroup>
                    <FormControlLabel control={<Switch defaultChecked={row.isActive} onChange={() => handleStatus(row.restaurantId,row.isActive)}/>} label="Active" />
                  </FormGroup>
                </TableCell>
                
              </TableRow>
              ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No records found.</TableCell>
                </TableRow>
            )}
           </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      </div>
    </div>
  );
};

export default RestaurantList;
