import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { TextField, Button, Grid, makeStyles, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import axios from 'axios';
import '../index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import slugify from "slugify";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

const NewQuizLevel = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [level, setLevel] = useState(1);
  const [timeLimit, setTimeLimit] = useState('');
  const [numQuestions, setNumQuestions] = useState('');

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const userID = JSON.parse(localStorage.getItem('user')).result._id;
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/quizLevels`;
    const data = {
      name: name,
      timeLimit: timeLimit,
      numQuestions: numQuestions,
      level: level,
      userID: userID
    };
    const formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    // formData.append("file", file);
    const result = await axios({
      method: 'post',
      url: baseUrl,
      data: formData,
      headers: {'Content-Type' : 'application/json'},
    });
    //const result = await axios.post(baseUrl, data);
    if (result) {
      console.log(result);
      navigate('/quizlevel-list')
    }


  }

  // const saveFile = (e) => {
  //     setFile(e.target.files[0]);
  //     setFileName(e.target.files[0].name);
  // };

  return (
    <>

      <div className='content-wrapper'>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">New Quiz Level</h1>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>

        <div className='adminform-background px-3 mx-3 py-3'>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Level Timelimit"
                  value={timeLimit}
                  onChange={(e) => setTimeLimit(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Number of Questions"
                  value={numQuestions}
                  onChange={(e) => setNumQuestions(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="level-label">Level #</InputLabel>
                  <Select
                    labelId="team-select-label"
                    id="level"
                    name='level'
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                    style={{ width: "100%" }}
                  >
                    {Array.from(Array(100+1).keys()).slice(1).map(x => <MenuItem key={x} value={x}>{`Level ${x}`}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Create Quiz Level
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  )
}

export default NewQuizLevel