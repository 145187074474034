import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { TextField, Button, Grid, makeStyles, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import axios from 'axios';
import '../index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import slugify from "slugify";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

const NewItem = () => {
  const classes = useStyles();
  const [category,setCategory] = useState('classic');

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.forms.items;
    const userID = JSON.parse(localStorage.getItem('user')).result._id;
    const data = {
      name: form.name.value,
      description: form.description.value,
      qty: form.qty.value,
      rate: form.rate.value,
      isActive: true,
      createBy: userID
    }
    submitProduct(data); // Call the submitProduct function here
  };

  const submitProduct = async (data) => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/item`;
    const result = await axios.post(`${baseUrl}`, data);
    if (result.status === 201) {
      navigate('/item')
    } else {
      console.log(result);
      alert(result.error)
    }
  }

  return (
    <>
      <React.Fragment>
        <div className='wrapper'>
          <Header />
          <Sidebar />
          <div className='content-wrapper'>
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">Add New Item</h1>
                  </div>{/* /.col */}
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    </ol>
                  </div>{/* /.col */}
                </div>{/* /.row */}
              </div>{/* /.container-fluid */}
            </div>

            <div className='adminform-background px-3 mx-3 py-3'>
              <form onSubmit={handleSubmit} name="items">
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      label="Name"
                      name="name"
                      id="name"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Description"
                      name="description"
                      id="description"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>

                    <TextField
                      label="Tokes"
                      name="qty"
                      id="qty"
                      placeholder='Tokens'
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>

                    <TextField
                      label="Rate"
                      name="rate"
                      id="rate"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit">
                      Create Item
                    </Button>
                  </Grid>
                </Grid>

              </form>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    </>
  )
}

export default NewItem;